import { FUTURES_ENDPOINT_OP_MAINNET } from '@kwenta/sdk/constants'
import type { NetworkId } from '@kwenta/sdk/types'
import { getPerpsV2SubgraphUrl } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { useQuery } from '@tanstack/react-query'
import request, { gql } from 'graphql-request'

import QUERY_KEYS from 'constants/queryKeys'
import ROUTES from 'constants/routes'
import Connector from 'containers/Connector'
import logError from 'utils/logError'

import type { FuturesCumulativeStats } from './types'

const useGetFuturesCumulativeStats = () => {
	const { network } = Connector.useContainer()
	const homepage = window.location.pathname === ROUTES.Home.Root
	const futuresEndpoint = homepage
		? FUTURES_ENDPOINT_OP_MAINNET
		: getPerpsV2SubgraphUrl(network?.id)

	return useQuery<FuturesCumulativeStats | null>({
		queryKey: QUERY_KEYS.Futures.TotalTrades(network?.id as NetworkId),
		queryFn: async () => {
			try {
				// TODO: Move request to SDK
				const response: any = await request(
					futuresEndpoint,
					gql`
						query FuturesCumulativeStats {
							futuresCumulativeStat(id: "0") {
								totalTrades
								totalTraders
								totalVolume
								totalLiquidations
								averageTradeSize
							}
						}
					`
				)

				return response
					? {
							totalVolume: wei(response.futuresCumulativeStat.totalVolume, 18, true).toString(),
							averageTradeSize: wei(
								response.futuresCumulativeStat.averageTradeSize,
								18,
								true
							).toString(),
							totalTraders: response.futuresCumulativeStat.totalTraders,
							totalTrades: response.futuresCumulativeStat.totalTrades,
							totalLiquidations: response.futuresCumulativeStat.totalLiquidations,
						}
					: null
			} catch (e) {
				logError(e)
				return null
			}
		},
		enabled: homepage,
	})
}

export default useGetFuturesCumulativeStats
